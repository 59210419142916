import { render, staticRenderFns } from "./CommodityList.vue?vue&type=template&id=247c0003&scoped=true&"
import script from "./CommodityList.vue?vue&type=script&lang=js&"
export * from "./CommodityList.vue?vue&type=script&lang=js&"
import style0 from "./CommodityList.vue?vue&type=style&index=0&id=247c0003&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247c0003",
  null
  
)

export default component.exports